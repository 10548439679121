import {
  Box,
  Button,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import { MouseEventHandler, useMemo, useState } from "react";
import { useModal } from "src/components/organisms/context_modal/context_modal";
import { useNotifier } from "src/components/organisms/context_snackbar/context_snackbar";
import { useRemainingGameTime } from "src/components/pages/owner/table/use_gametime";
import { OWNER_TABLE_DETAIL_PAGE_FULL_PATH } from "src/components/pages/paths";
import {
  GameID,
  cancelAlcohol,
  extendGameTime,
  finishGame,
  initializeGame,
  startGame,
  orderAlcohol,
  skipAlcohol,
} from "src/core/game";
import { ProblemID } from "src/core/problem";
import {
  TableID,
  cleanUpFirebaseTable,
  upsertFirebaseTable,
} from "src/core/table";
import { useRealtimeDatabase } from "src/firebase/realtime_database/hooks";
import {
  IzakayaRealTimeDataBaseData,
  Progress,
} from "src/firebase/realtime_database/type";

export const TableInfo = (
  props: Partial<IzakayaRealTimeDataBaseData["table"][number]> & {
    tableID: TableID;
    gameID?: GameID;
    configData: Partial<IzakayaRealTimeDataBaseData["config"]>;
  }
) => {
  const gameConfigData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][string]["config"]
  >(`game/${props.gameID}/config`);

  const gameStatusData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][string]["status"]
  >(`game/${props.gameID}/status`);

  const timestamp = useMemo(() => {
    if (gameConfigData?.startAt === undefined) {
      return undefined;
    }
    return dayjs(gameConfigData?.startAt);
  }, [gameConfigData?.startAt]);

  const [remainingMinutes, remainingSeconds, expired] = useRemainingGameTime(
    timestamp,
    gameConfigData?.gameTimeMinutes
  );

  if (!props.gameID || timestamp === undefined) {
    return <UnStartedTable tableID={props.tableID} />;
  }

  if (gameStatusData?.progress === "finished") {
    return (
      <FinishedTable
        drunkennessLevel={gameStatusData?.drunkennessLevel ?? 0}
        memberAmount={props.memberAmount ?? 0}
        gameID={props.gameID}
        tableID={props.tableID}
        remainingMinutes={remainingMinutes ?? 100}
        remainingSeconds={remainingSeconds ?? 100}
        gameTimeMinutes={props.configData?.gameTimeMinutes ?? 100}
      />
    );
  } else {
    return (
      <PlayingTable
        progress={gameStatusData?.progress ?? "waiting"}
        score={gameStatusData?.score ?? 0}
        drunkennessLevel={gameStatusData?.drunkennessLevel ?? 0}
        memberAmount={props.memberAmount ?? 0}
        gameID={props.gameID}
        tableID={props.tableID}
        remainingMinutes={remainingMinutes ?? 100}
        remainingSeconds={remainingSeconds ?? 100}
        gameTimeMinutes={props.configData?.gameTimeMinutes ?? 100}
        timeExpired={expired === undefined ? false : expired}
      />
    );
  }
};

const CustomerOrderForm = (props: {
  gameID: GameID;
  customerID: number;
  orderedProblemID?: ProblemID;
}) => {
  const notifier = useNotifier();

  if (props.orderedProblemID === undefined) {
    return (
      <>
        <Button
          disabled={props.orderedProblemID !== undefined}
          variant="contained"
          sx={{ marginRight: "10px" }}
          onClick={() => {
            orderAlcohol(
              props.gameID,
              props.customerID,
              "weak-alcohol",
              0
            ).catch((error) => {
              console.log("error", error);
              notifier.notifyError("注文できる酒がありません！");
            });
          }}
        >
          弱アル
        </Button>
        <Button
          disabled={props.orderedProblemID !== undefined}
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            const randomIndex = 0;
            orderAlcohol(
              props.gameID,
              props.customerID,
              "strong-alcohol",
              randomIndex
            ).catch((error) => {
              console.log("error", error);
              notifier.notifyError("注文できる酒がありません！");
            });
          }}
        >
          強アル
        </Button>
        <span style={{ marginLeft: "10px" }}>注文なし</span>
      </>
    );
  } else {
    return (
      <>
        <Button
          variant="contained"
          sx={{ marginRight: "10px" }}
          onClick={() => {
            if (props.orderedProblemID !== undefined) {
              cancelAlcohol(
                props.gameID,
                props.customerID,
                props.orderedProblemID
              );
            }
          }}
        >
          キャンセル
        </Button>
        <Button
          variant="contained"
          sx={{ marginRight: "10px" }}
          onClick={() => {
            if (props.orderedProblemID !== undefined) {
              skipAlcohol(
                props.gameID,
                props.customerID,
                props.orderedProblemID
              );
            }
          }}
        >
          スキップ
        </Button>
        <span style={{ marginRight: "10px" }}>
          {`注文済み ${props.orderedProblemID}`}
        </span>
      </>
    );
  }
};

const PlayingTable = (props: {
  progress: Progress;
  tableID: TableID;
  gameID: GameID;
  memberAmount: number;
  remainingMinutes: number;
  remainingSeconds: number;
  gameTimeMinutes: number;
  score: number;
  drunkennessLevel: number;
  timeExpired: boolean;
}) => {
  const modalController = useModal();
  const displayTableID = useMemo(() => props.tableID + 1, [props.tableID]);
  const gameConfig = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][GameID]["config"]
  >(`game/${props.gameID}/config`);

  const paddedSecond = useMemo(() => {
    return String(props.remainingSeconds).padStart(2, "0");
  }, [props.remainingSeconds]);

  const paddedMinutes = useMemo(() => {
    return String(props.remainingMinutes).padStart(2, "0");
  }, [props.remainingMinutes]);

  const orderData = useRealtimeDatabase<
    IzakayaRealTimeDataBaseData["game"][GameID]["order"]
  >(`game/${props.gameID}/order`);

  const rows = useMemo(() => {
    const customerOrderData = [...Array(props.memberAmount)].map((_, index) => {
      return {
        id: `order-${index}`,
        key: `Customer ${index + 1}`,
        value: (
          <CustomerOrderForm
            gameID={props.gameID}
            customerID={index}
            orderedProblemID={orderData?.[index]?.alcohol}
          />
        ),
      };
    });
    const finishButton = props.timeExpired
      ? [
          {
            id: "finish",
            key: "終了",
            value: (
              <Button
                variant="contained"
                onClick={() => {
                  finishGame(props.gameID);
                }}
              >
                スコアを記録して終了
              </Button>
            ),
          },
        ]
      : [];

    return [
      { id: 1, key: "GameID", value: props.gameID },
      { id: 2, key: "人数", value: props.memberAmount },
      { id: 3, key: "スコア", value: props.score },
      { id: 4, key: "泥酔度", value: props.drunkennessLevel },
      {id: 5, key: "名前", value: gameConfig?.password},
      {
        id: 5,
        key: "残り時間",
        value:
          props.progress === "waiting"
            ? "開始前"
            : `${paddedMinutes}:${paddedSecond}`,
      },
      ...customerOrderData,
      ...finishButton,
    ];
  }, [gameConfig?.password, orderData, paddedMinutes, paddedSecond, props.drunkennessLevel, props.gameID, props.memberAmount, props.progress, props.score, props.timeExpired]);

  const [contextMenu, setContextMenu] = useState<
    | {
        mouseX: number;
        mouseY: number;
      }
    | undefined
  >(undefined);
  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === undefined
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : undefined
    );
  };

  const handleClose = () => {
    setContextMenu(undefined);
  };

  return (
    <Box key={props.tableID} padding={3}>
      <TableContainer
        component={Paper}
        onDoubleClick={handleContextMenu}
        onContextMenu={handleContextMenu}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Table</TableCell>
              <TableCell>{displayTableID}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row["key"]}>
                  <TableCell>{row["key"]}</TableCell>
                  <TableCell>{row["value"]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Menu
        open={contextMenu !== undefined}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== undefined
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            const absoluteURL = new URL(
              `/game/${props.gameID}`,
              window.location.href
            );
            // Open the URL in a new tab
            window.open(absoluteURL.href, "_blank");

            // navigate(`/game/${props.gameID}`);
          }}
        >
          このテーブルのゲーム画面に移動する
        </MenuItem>
        <MenuItem
          onClick={() => {
            const absoluteURL = new URL(
              `${OWNER_TABLE_DETAIL_PAGE_FULL_PATH}?tableID=${props.tableID}`,
              window.location.href
            );
            // Open the URL in a new tab
            window.open(absoluteURL.href, "_blank");

            // navigate(`/game/${props.gameID}`);
          }}
        >
          このテーブルの詳細画面に移動する
        </MenuItem>
        <MenuItem
          onClick={() => {
            extendGameTime(props.gameID, 60);
          }}
        >
          このテーブルを1分延長する
        </MenuItem>
        <MenuItem
          onClick={() => {
            startGame(props.gameID, props.gameTimeMinutes);
          }}
        >
          このテーブルを開始する
        </MenuItem>

        <MenuItem
          onClick={() => {
            modalController.open(
              <div style={{}}>
                まじで終了する？
                <Button
                  variant="contained"
                  onClick={() => {
                    cleanUpFirebaseTable(props.tableID);
                    handleClose();
                    modalController.close();
                  }}
                >
                  うん
                </Button>
              </div>
            );
          }}
        >
          このテーブルを強制終了する
        </MenuItem>
      </Menu>
    </Box>
  );
};

const FinishedTable = (props: {
  tableID: TableID;
  gameID: GameID;
  memberAmount: number;
  remainingMinutes: number;
  remainingSeconds: number;
  gameTimeMinutes: number;
  drunkennessLevel: number;
}) => {
  const displayTableID = useMemo(() => props.tableID + 1, [props.tableID]);

  const [memberAmount, setMemberAmount] = useState<number>(0);
  const configData =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["config"]>("config");

  const isInvalidValue = useMemo(() => {
    return memberAmount == 0 || memberAmount > (configData?.maxMember ?? 10);
  }, [configData?.maxMember, memberAmount]);

  const rows = useMemo(() => {
    return [
      { id: 1, key: "GameID", value: props.gameID },
      { id: 2, key: "人数", value: props.memberAmount },
      { id: 3, key: "泥酔度", value: props.drunkennessLevel },
    ];
  }, [props.drunkennessLevel, props.gameID, props.memberAmount]);

  const [contextMenu, setContextMenu] = useState<
    | {
        mouseX: number;
        mouseY: number;
      }
    | undefined
  >(undefined);
  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === undefined
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : undefined
    );
  };

  const handleClose = () => {
    setContextMenu(undefined);
  };
  return (
    <Box key={props.tableID} padding={3}>
      <TableContainer
        component={Paper}
        onDoubleClick={handleContextMenu}
        onContextMenu={handleContextMenu}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Table</TableCell>
              <TableCell>{displayTableID}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow key={row["key"]}>
                  <TableCell>{row["key"]}</TableCell>
                  <TableCell>{row["value"]}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: "flex", position: "relative", marginTop: "5px" }}>
        <TextField
          size="small"
          label="人数"
          type="number"
          sx={{ minWidth: "115px", width: "50%" }}
          inputProps={{ min: "1", max: "4", step: "1" }}
          placeholder="人数"
          onChange={(e) => {
            setMemberAmount(Number(e.target.value));
          }}
        />
        <Button
          onClick={() => {
            const uuid = crypto.randomUUID();
            initializeGame(
              uuid,
              memberAmount,
              configData?.gameTimeMinutes ?? 20 // protected by isValid
            );
            upsertFirebaseTable(props.tableID, {
              gameID: uuid,
              memberAmount: memberAmount,
            });
          }}
          style={{
            // sloppy margin...
            right: "20px",
            position: "absolute",
          }}
          disabled={isInvalidValue}
          variant="contained"
        >
          初期化
        </Button>
      </div>
      <Menu
        open={contextMenu !== undefined}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== undefined
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        componentsProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              handleClose();
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            const absoluteURL = new URL(
              `/game/${props.gameID}`,
              window.location.href
            );
            // Open the URL in a new tab
            window.open(absoluteURL.href, "_blank");

            // navigate(`/game/${props.gameID}`);
          }}
        >
          このテーブルのゲーム画面に移動する
        </MenuItem>
        <MenuItem
          onClick={() => {
            const absoluteURL = new URL(
              `${OWNER_TABLE_DETAIL_PAGE_FULL_PATH}?tableID=${props.tableID}`,
              window.location.href
            );
            // Open the URL in a new tab
            window.open(absoluteURL.href, "_blank");

            // navigate(`/game/${props.gameID}`);
          }}
        >
          このテーブルの詳細画面に移動する
        </MenuItem>
      </Menu>
    </Box>
  );
};

const UnStartedTable = (props: { tableID: TableID }) => {
  const [memberAmount, setMemberAmount] = useState<number>(0);
  const configData =
    useRealtimeDatabase<IzakayaRealTimeDataBaseData["config"]>("config");

  const isInvalidValue = useMemo(() => {
    return memberAmount == 0 || memberAmount > (configData?.maxMember ?? 10);
  }, [configData?.maxMember, memberAmount]);

  return (
    <Box
      key={props.tableID}
      sx={{
        "& > *": {
          m: 1,
        },
        padding: 3,
      }}
    >
      <p>まだテーブルが作成されていません。</p>
      <div style={{ display: "flex", position: "relative", marginTop: "5px" }}>
        <TextField
          size="small"
          label="人数"
          type="number"
          sx={{ minWidth: "115px", width: "50%" }}
          inputProps={{ min: "1", max: "4", step: "1" }}
          placeholder="人数"
          onChange={(e) => {
            setMemberAmount(Number(e.target.value));
          }}
        />
        <Button
          onClick={() => {
            const uuid = crypto.randomUUID();
            initializeGame(
              uuid,
              memberAmount,
              configData?.gameTimeMinutes ?? 99 // protected by isValid
            );
            upsertFirebaseTable(props.tableID, {
              gameID: uuid,
              memberAmount: memberAmount,
            });
          }}
          style={{
            // sloppy margin...
            right: "20px",
            position: "absolute",
          }}
          disabled={isInvalidValue}
          variant="contained"
        >
          初期化
        </Button>
      </div>
    </Box>
  );
};
