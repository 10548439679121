import { ProblemDefinition, ProblemID } from "src/core/problem";

/**
 * problem master data.
 */
export const PROBLEMS: Omit<ProblemDefinition, "status">[] = [
  {
    answer: ["お通し", "おとおし"],
    category: "speed",
    id: 0,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["酒盗", "しゅとう", "しゅとー"],
    category: "speed",
    id: 1,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["さんちゅ", "サンチュ"],
    category: "speed",
    id: 2,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["梅水晶", "うめすいしょう", "うめすいしょー"],
    category: "speed",
    id: 3,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["だしまきたまご", "だし巻き玉子"],
    category: "speed",
    id: 4,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["シーザーサラダ", "しーざーさらだ"],
    category: "speed",
    id: 5,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["ひじき"],
    category: "speed",
    id: 6,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["とまと", "トマト"],
    category: "speed",
    id: 7,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["しらす", "シラス"],
    category: "speed",
    id: 8,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["ポテトフライ", "ぽてとふらい"],
    category: "speed",
    id: 9,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["えだまめ", "枝豆"],
    category: "speed",
    id: 10,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["てんぷら"],
    category: "food",
    id: 101,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["あいなめ"],
    category: "food",
    id: 102,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["おでん"],
    category: "food",
    id: 103,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["キムチ", "きむち"],
    category: "food",
    id: 104,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["さしみ", "刺身"],
    category: "food",
    id: 105,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["うに", "雲丹"],
    category: "food",
    id: 106,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["とろ", "トロ"],
    category: "food",
    id: 107,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["にんにく", "ニンニク"],
    category: "food",
    id: 108,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["馬刺し", "ばさし"],
    category: "food",
    id: 109,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["ほっけ", "ホッケ"],
    category: "food",
    id: 110,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["たれかつ", "タレカツ"],
    category: "food",
    id: 111,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["やきとり", "焼き鳥"],
    category: "food",
    id: 112,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["ゆば", "湯葉"],
    category: "recommend",
    id: 201,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["くえ", "クエ"],
    category: "recommend",
    id: 202,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["らむ", "ラム"],
    category: "recommend",
    id: 203,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["あぶりしめさば"],
    category: "recommend",
    id: 204,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["ひないじどり", "比内地鶏"],
    category: "recommend",
    id: 205,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["うどん"],
    category: "finish",
    id: 301,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["ちゃーはん"],
    category: "finish",
    id: 302,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["ほるもんうどん"],
    category: "finish",
    id: 303,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["みそらーめん"],
    category: "finish",
    id: 304,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["かまめし"],
    category: "finish",
    id: 305,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["ばにら", "バニラ", "ばにらあいす"],
    category: "dessert",
    id: 401,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["杏仁豆腐", "あんにんどうふ"],
    category: "dessert",
    id: 402,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["じぇらーと"],
    category: "dessert",
    id: 403,
    score: 0,
    alcoholLevel: 0,
  },
  {
    answer: ["マッコリ", "まっこり"],
    category: "weak-alcohol",
    id: 501,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["ハイボール", "はいぼーる"],
    category: "weak-alcohol",
    id: 502,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: [
      "シークワーサーサワー",
      "シークワーサー",
      "しーくわーさー",
      "しーくわーさーさわー",
      "しーくゎーさー",
      "しーくゎーさーさわー",
      "シークヮーサー",
      "シークヮーサーサワー",
    ],
    category: "weak-alcohol",
    id: 503,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["甘酒", "あまざけ"],
    category: "weak-alcohol",
    id: 504,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["ジンジャーハイ", "じんじゃーはい"],
    category: "weak-alcohol",
    id: 505,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["コークハイ", "こーくはい"],
    category: "weak-alcohol",
    id: 506,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["緑茶ハイ", "りょくちゃはい"],
    category: "weak-alcohol",
    id: 507,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["ライムサワー", "らいむ", "ライム", "らいむさわー"],
    category: "weak-alcohol",
    id: 508,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["シャンディーガフ", "しゃんでぃーがふ", "しゃんでぃがふ", "シャンディガフ"],
    category: "weak-alcohol",
    id: 509,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["ピーチサワー", "ぴーちさわー", "ぴーち", "ピーチ"],
    category: "weak-alcohol",
    id: 510,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["ウーロンハイ", "うーろんはい"],
    category: "weak-alcohol",
    id: 511,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["みかんサワー", "みかんさわー"],
    category: "weak-alcohol",
    id: 512,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["レモンサワー", "れもんさわー", "れもん", "レモン"],
    category: "weak-alcohol",
    id: 513,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["カシスオレンジ", "かしすおれんじ", "かしおれ", "カシオレ"],
    category: "weak-alcohol",
    id: 514,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["キティ", "きてぃ"],
    category: "weak-alcohol",
    id: 515,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["ファジーネーブル", "ふぁじーねーぶる"],
    category: "weak-alcohol",
    id: 516,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["カルーアミルク", "かるーあみるく"],
    category: "weak-alcohol",
    id: 517,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["レッドアイ", "れっどあい", "redeye", "REDEYE"],
    category: "weak-alcohol",
    id: 518,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["ビール", "びーる", "beer"],
    category: "weak-alcohol",
    id: 519,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["照葉樹林", "しょうようじゅりん"],
    category: "weak-alcohol",
    id: 520,
    score: 0,
    alcoholLevel: 7.5,
  },
  {
    answer: ["梅酒", "うめしゅ"],
    category: "strong-alcohol",
    id: 601,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["ドンペリ", "どんぺり"],
    category: "strong-alcohol",
    id: 602,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["ワイン", "わいん", "wine"],
    category: "strong-alcohol",
    id: 603,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["モヒート", "もひーと"],
    category: "strong-alcohol",
    id: 604,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["泡盛", "あわもり"],
    category: "strong-alcohol",
    id: 605,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["しゃんぱん", "シャンパン"],
    category: "strong-alcohol",
    id: 606,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["ジン", "じん", "GIN", "gin"],
    category: "strong-alcohol",
    id: 607,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["ブランデー", "ぶらんでー"],
    category: "strong-alcohol",
    id: 608,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["ジントニック", "じんとにっく"],
    category: "strong-alcohol",
    id: 609,
    score: 0,
    alcoholLevel: 14,
  },
  {
    answer: ["焼酎", "しょうちゅう", "ショウチュウ"],
    category: "strong-alcohol",
    id: 610,
    score: 0,
    alcoholLevel: 25,
  },
  {
    answer: ["スクリュードライバー", "すくりゅーどらいばー"],
    category: "strong-alcohol",
    id: 611,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["モスコミュール", "もすこみゅーる"],
    category: "strong-alcohol",
    id: 612,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: [
      "ソルティドッグ",
      "そるてぃどっぐ",
      "そるてぃーどっぐ",
      "ソルティードッグ",
    ],
    category: "strong-alcohol",
    id: 613,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["ジンバック", "じんばっく"],
    category: "strong-alcohol",
    id: 614,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["テキーラ", "てきーら"],
    category: "strong-alcohol",
    id: 615,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["スピリタス", "すぴりたす"],
    category: "strong-alcohol",
    id: 616,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["日本酒", "にほんしゅ"],
    category: "strong-alcohol",
    id: 617,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["ハブ酒", "はぶしゅ", "ハブシュ"],
    category: "strong-alcohol",
    id: 618,
    score: 0,
    alcoholLevel: 35,
  },
  {
    answer: ["紹興酒", "しょうこうしゅ", "しょーこーしゅ"],
    category: "strong-alcohol",
    id: 619,
    score: 0,
    alcoholLevel: 35,
  },
  {
    answer: [
      "ウイスキー",
      "ういすきー",
      "ウィスキー",
      "うぃすきー",
      "whisky",
      "WHISKY",
    ],
    category: "strong-alcohol",
    id: 620,
    score: 0,
    alcoholLevel: 40,
  },
  {
    answer: ["ラム", "らむ"],
    category: "strong-alcohol",
    id: 621,
    score: 0,
    alcoholLevel: 40,
  },
  {
    answer: ["バーボン", "ばーぼん", "bourbon", "BOURBON"],
    category: "strong-alcohol",
    id: 622,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["ウォッカ", "うぉっか"],
    category: "strong-alcohol",
    id: 623,
    score: 0,
    alcoholLevel: 10,
  },
  {
    answer: ["マリブコーク", "まりぶこーく"],
    category: "strong-alcohol",
    id: 624,
    score: 0,
    alcoholLevel: 9,
  },
  {
    answer: ["アマレット", "あまれっと"],
    category: "strong-alcohol",
    id: 625,
    score: 0,
    alcoholLevel: 24,
  },
];

export const PROBLEM_DICT: {
  [problemID: ProblemID]: Omit<ProblemDefinition, "status">;
} = {};
PROBLEMS.forEach((problem) => {
  PROBLEM_DICT[problem.id] = problem;
});
